@use "global" as *;

.p-banner1 {
  background: url(../images/logo.png) repeat center / rem(150) rem(150);
  animation: bgiLoop 5s linear infinite;
}

.p-banner1__title {
  max-width: rem(880);
  width: 90%;
  margin: auto;
}

.p-banner__body {
  min-width: rem(280);
  max-width: rem(280);
  position: relative;
  margin: rem(20) auto rem(110);

  @include mq(md) {
    max-width: rem(680);
    min-width: rem(680);
  }
}

.p-banner1__img-desktop {
  max-width: rem(230);
  width: 100%;
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;

  @include mq(md) {
    max-width: rem(560);
  }
}

.p-banner1__img-desktop img:nth-child(2) {
  width: rem(110);
  position: absolute;
  top: rem(35);
  right: rem(14);
  background-color: #f7f6f5;

  @include mq(md) {
    width: rem(260);
    top: rem(90);
    right: rem(40);
  }
}

.p-banner1__img-sp {
  width: rem(70);
  position: absolute;
  top: rem(41);
  left: 0;
  z-index: 2;

  @include mq(md) {
    width: rem(180);
    top: rem(82);
  }
}

.p-banner1__img-sp img:nth-child(2) {
  width: rem(54);
  position: absolute;
  top: rem(60);
  right: 0;
  left: 0;
  margin: auto;
  background-color: #f7f6f5;

  @include mq(md) {
    width: rem(134);
    top: rem(160);
  }
}

.p-banner1__btn {
  margin-top: rem(70);
  text-align: center;
  position: relative;

  @include mq(md) {
    margin-top: 0;
  }
}

.p-banner1__btn-img {
  width: 90%;
  position: absolute;
  bottom: rem(60);
  right: 0;
  left: 0;
  margin: auto;
  text-align: right;
  max-width: rem(1100);
  z-index: 1;

}

.p-banner1__btn-img img {
  width: rem(150);
  display: inline-block;

  @include mq(md) {
    width: rem(260);
  }
}

.p-banner__box {
  padding: rem(90) rem(20) rem(60) rem(20);
  max-width: rem(1020);
  width: 90%;
  margin: rem(-200) auto 0;
  background-color: $white;
  border: 3px solid;
  border-radius: rem(3);

  @include mq(md) {
    padding: rem(100) rem(40) rem(60);
    margin: rem(-200) auto rem(60);

  }
}

.p-banner__box-head {
  font-size: rem(20);
  margin-bottom: rem(40);
  width: 100%;
  font-weight: bold;
  padding: rem(30) 0 rem(60) 0;
  line-height: 1;
  text-align: center;
  border-bottom: 3px solid;

  @include mq(md) {
    font-size: rem(32);
  }
}

.p-banner__box-head span {
  font-size: rem(50);
  padding-top: rem(10);
  display: inline-block;
  font-family: $font-robo;

  @include mq(md) {
    font-size: rem(80);
  }
}

.p-banner__box-wrap {
  @include mq(md) {
    display: flex;
  }
}

.p-banner__price {
  width: 100%;
  padding: rem(60) 0 rem(50);
  margin-bottom: rem(20);
  border-radius: rem(10);
  background-color: #f7f6f5;
  position: relative;

  @include mq(md) {
    width: rem(380);
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.p-banner__fukidasi {
  font-size: rem(12);
  width: rem(100);
  height: rem(100);
  color: $white;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  top: rem(-20);
  left: rem(-20);
  z-index: 1;

  @include mq(md) {
    font-size: rem(18);
    width: rem(140);
    height: rem(140);
    line-height: 1.3;
  }
}

.p-banner__fukidasi::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: url(../images/plan_tag.png) no-repeat center / cover;
  animation: rotate-fukidashi 5s linear infinite both;
}

.p-banner__price-text {
  font-size: rem(90);
  font-weight: bold;
  line-height: 1;
  text-align: center;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: rem(4);

  @include mq(md) {
    font-size: rem(120);
  }
}

.p-banner__price-text .tag {
  font-size: 15%;
  display: inline-block;
  padding: rem(4) rem(6);
  background-color: #222;
  color: $white;
  border-radius: 2px;
  margin-right: 4px;
}

.p-banner__price-text .num {
  font-family: $font-robo;
  letter-spacing: -0.05em;
}

.p-banner__price-text small {
  font-size: rem(20);
}

.p-banner__price-sub-text {
  margin-top: rem(20);
  font-size: rem(24);
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.p-banner__price-sub-text .tag {
  display: inline-block;
  background-color: #222;
  color: $white;
  padding: rem(2) rem(4);
  border-radius: 2px;
  margin-right: 4px;
  font-size: 60%;
}

.p-banner__price-sub-text .num {
  font-size: 120%;
  font-weight: bold;
  position: relative;
  bottom: rem(-4);
  line-height: 1;
  font-family: $font-robo;
}

.p-banner__price-sub-text small {
  font-size: 50%;
  position: relative;
  bottom: rem(-2);
  font-weight: bold;
}

.p-banner__list {
  margin-top: rem(20);
  font-size: rem(11);

  @include mq(md) {
    flex: 1;
    padding: 0 rem(30);
    font-size: rem(14);
    margin-top: 0;
  }
}

.p-banner__item {
  border-radius: rem(3);
  padding: rem(8);
  font-weight: bold;
  background-color: #f7f6f5;
  display: flex;
  align-items: center;
}

.p-banner__item::before {
  content: "";
  display: block;
  width: rem(8);
  height: rem(8);
  margin-right: rem(6);
  background-color: $black;
}

.p-banner__item:nth-child(n+2) {
  margin-top: rem(7);
}

@keyframes bgiLoop {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: rem(150) rem(-150);
  }
}

@keyframes rotate-fukidashi {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}