@charset "UTF-8";
html {
  font-size: 100%;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.4159292035vw;
  }
}
@media (min-width: 1130px) {
  html {
    font-size: 100%;
  }
}

body {
  font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
  color: #000;
}

@media screen and (min-width: 768px) {
  a,
  button {
    transition: 0.3s;
  }
  a:hover,
  button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

/*****************************
* A Modern CSS Reset (https://github.com/hankchizljaw/modern-css-reset)
* 上記に、ul要素,ol要素,a要素への記述追加
*****************************/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
li {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.l-about {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .l-about {
    padding: 7.5rem 0;
  }
}

.l-banner1 {
  padding: 9.375rem 0;
  margin-top: 6.25rem;
}
@media screen and (min-width: 768px) {
  .l-banner1 {
    padding: 17.5rem 0 11.25rem;
    margin-top: 10.625rem;
  }
}

.l-case {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .l-case {
    padding: 6.25rem 0;
  }
}

.l-concept {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .l-concept {
    padding: 7.5rem 0;
  }
}

.l-feature {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .l-feature {
    padding: 6.25rem 0;
  }
}

.l-header {
  height: 3.75rem;
  padding: 0 1.25rem;
}
@media screen and (min-width: 768px) {
  .l-header {
    height: 6.25rem;
  }
}

.l-inner {
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .l-inner {
    max-width: 1130px;
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
}

.l-merit {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .l-merit {
    padding: 7.5rem 0;
  }
}

.l-news {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .l-news {
    padding: 6.25rem 0;
  }
}

.l-step {
  margin-top: 6.25rem;
}
@media screen and (min-width: 768px) {
  .l-step {
    margin-top: 7.5rem;
  }
}

.c-btn {
  width: 80%;
  font-size: 0.9375rem;
  padding: 1.875rem;
  display: inline-block;
  font-weight: bold;
  line-height: 1;
  border-radius: 6.25rem;
  max-width: 47.5rem;
  text-align: center;
  position: relative;
  background-color: #000;
  color: #fff;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .c-btn {
    font-size: 2.25rem;
    padding: 2.5rem 3.75rem;
  }
}

.c-card-case__title {
  display: flex;
  align-items: center;
  line-height: 1.6em;
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .c-card-case__title {
    margin-bottom: 1.875rem;
    align-items: flex-start;
  }
}

.c-card-case__title::before {
  content: "";
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 1.25rem;
  background: url(../images/case3.png) no-repeat center/60%;
  background-color: #f7f6f5;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .c-card-case__title::before {
    width: 7.5rem;
    height: 7.5rem;
    margin-right: 2.5rem;
    background: url(../images/case3.png) no-repeat center/4.375rem;
    background-color: #f7f6f5;
  }
}

.c-card-case__title span {
  font-size: 1rem;
  line-height: 2em;
  padding: 0.625rem;
  position: relative;
  background-color: #f7f6f5;
  border-radius: 5rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  flex: 1;
  text-align: center;
  box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 768px) {
  .c-card-case__title span {
    font-size: 2.125rem;
    line-height: 1.5em;
    padding: 2.5rem 3.125rem;
    margin-right: 10.625rem;
  }
}

.c-card-case__title span::before {
  content: "";
  position: absolute;
  left: 0;
  border: 0.9375rem solid transparent;
  border-right: 1.875rem solid #f7f6f5;
  top: 28%;
  margin-left: -1.5625rem;
}
@media screen and (min-width: 768px) {
  .c-card-case__title span::before {
    top: 40%;
    margin-left: -2.5rem;
  }
}

.c-card-case__body {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .c-card-case__body {
    flex-direction: row;
  }
}

.c-card-case__text {
  padding: 0.9375rem 1.25rem;
  font-size: 0.8125rem;
  line-height: 2em;
  background: #f7f6f5;
  border-radius: 0.625rem;
  flex: 1;
  position: relative;
  box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.2);
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-card-case__text {
    line-height: 1.9em;
    font-size: 1.0625rem;
    padding: 2.5rem 3.125rem;
    margin-left: 10.625rem;
  }
}

.c-card-case__text::before {
  content: "";
  position: absolute;
  top: 40%;
  left: 3.125rem;
  border: 0.9375rem solid transparent;
  border-left: 1.875rem solid #f7f6f5;
  margin-left: -2rem;
  transform: rotate(180deg);
  transform-origin: left;
}
@media screen and (min-width: 768px) {
  .c-card-case__text::before {
    left: auto;
    right: -2.5rem;
    margin-left: -2.5rem;
    transform: rotate(0);
  }
}

.c-card-case__img {
  margin-right: 1.25rem;
}
@media screen and (min-width: 768px) {
  .c-card-case__img {
    margin-right: 0;
    margin-left: 2.5rem;
  }
}

.c-card-case__img img {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-card-case__img img {
    width: 7.5rem;
    height: 7.5rem;
  }
}

.c-card-feature {
  width: 100%;
  position: relative;
  padding: 2.375rem 1.75rem;
  margin-bottom: 3.125rem;
  background-color: #fff;
  box-shadow: 0.4375rem 0.4375rem 0 rgba(255, 255, 255, 0.2);
}
@media screen and (min-width: 768px) {
  .c-card-feature {
    padding: 3.125rem;
    margin-bottom: 5rem;
  }
}

.c-card-feature:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .c-card-feature:nth-child(n+4) {
    margin-bottom: 0;
  }
}

.c-card-feature:nth-child(1) {
  padding-top: 21.875rem;
}
@media screen and (min-width: 768px) {
  .c-card-feature:nth-child(1) {
    padding-top: 3.125rem;
  }
}

@media screen and (min-width: 768px) {
  .c-card-feature:nth-child(2),
  .c-card-feature:nth-child(3) {
    width: 48%;
  }
}

@media screen and (min-width: 768px) {
  .c-card-feature:nth-child(4),
  .c-card-feature:nth-child(5),
  .c-card-feature:nth-child(6) {
    width: 31%;
    padding: 1.875rem;
  }
}

.c-card-feature__num {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 3.75rem;
  line-height: 1;
}

.c-card-feature__icon {
  position: absolute;
  top: -1.875rem;
  right: 1.125rem;
  width: 7.5rem;
  z-index: 3;
}

@media screen and (min-width: 768px) {
  .c-card-feature:nth-child(1) .c-card-feature__body {
    width: 45%;
  }
}

.c-card-feature__title {
  font-weight: bold;
  line-height: 1.6em;
  font-size: 1.25rem;
  letter-spacing: 0.08rem;
  margin-top: 1.125rem;
  margin-bottom: 0.625rem;
}

.c-card-feature__text {
  font-size: 0.9375rem;
  letter-spacing: 0.02em;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .c-card-feature__text {
    font-size: 1rem;
    line-height: 1.6;
    opacity: 0.8;
  }
}

.c-card-feature__img {
  width: 100%;
  height: 18.75rem;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/feature__image1.png) repeat center/42rem 35.25rem;
  animation: bgiLoop_thumb_min 50s linear infinite;
}
@media screen and (min-width: 768px) {
  .c-card-feature__img {
    width: 50%;
    background: url(../images/feature__image1.png) repeat center/70rem 58.75rem;
    height: 100%;
  }
}

@keyframes bgiLoop_thumb_min {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 42rem -35.25rem;
  }
}
.c-card-merit {
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-card-merit {
    max-width: 60rem;
    width: 90%;
    margin: auto;
  }
}

.c-card-merit__img {
  width: 120%;
  margin-bottom: -4.375rem;
  position: relative;
  top: 0;
  right: -5%;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .c-card-merit__img {
    width: 130%;
    position: absolute;
    top: -5.625rem;
    right: -45%;
  }
}

.c-card-merit:nth-child(2) .c-card-merit__img {
  width: 21.25rem;
  left: auto;
  right: -1.25rem;
  margin-bottom: -3.75rem;
}
@media screen and (min-width: 768px) {
  .c-card-merit:nth-child(2) .c-card-merit__img {
    width: 42.5rem;
    height: auto;
    position: absolute;
    top: -6.875rem;
    left: -3.75rem;
  }
}

.c-card-merit:nth-child(3) .c-card-merit__img {
  max-width: 23.75rem;
  width: 100%;
  right: 0;
  top: 0.625rem;
  margin-bottom: -2.5rem;
}
@media screen and (min-width: 768px) {
  .c-card-merit:nth-child(3) .c-card-merit__img {
    right: -11.25rem;
    top: -6.875rem;
    max-width: 46.25rem;
    width: 70%;
    z-index: 3;
  }
}

.c-card-merit:nth-child(3) .c-card-merit__img img:nth-child(1) {
  position: relative;
}

.c-card-merit:nth-child(3) .c-card-merit__img img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.c-card-merit__body {
  padding: 1.875rem;
  position: relative;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .c-card-merit__body {
    z-index: 2;
    padding: 3.75rem 3.125rem;
    max-width: 33.75rem;
    width: 50%;
  }
}

.c-card-merit:nth-child(2) .c-card-merit__body {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .c-card-merit:nth-child(2) .c-card-merit__body {
    margin-right: 0;
    margin-left: auto;
  }
}

.c-card-merit__num {
  font-size: 4.375rem;
  line-height: 1;
  letter-spacing: -2px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
@media screen and (min-width: 768px) {
  .c-card-merit__num {
    font-size: 2.5rem;
  }
}

.c-card-merit__num::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 20px;
  border-color: transparent transparent transparent #000;
  display: block;
}

.c-card-merit__title {
  font-size: 1.5rem;
  line-height: 1.6;
  padding-bottom: 0.625rem;
  padding-top: 1.125rem;
  letter-spacing: 0.04em;
}
@media screen and (min-width: 768px) {
  .c-card-merit__title {
    font-size: 1.625rem;
    line-height: 1.7em;
    padding: 1.25rem 0;
  }
}

.c-card-merit__text {
  font-size: 1rem;
  line-height: 2em;
  letter-spacing: 0.01em;
}

.c-card-merit__bottom-img {
  width: 16.25rem;
  position: absolute;
  bottom: -8.125rem;
  left: -6.25rem;
}
@media screen and (min-width: 768px) {
  .c-card-merit__bottom-img {
    width: 33.75rem;
    left: -16.25rem;
    bottom: -18.125rem;
    z-index: 2;
  }
}

@media screen and (min-width: 768px) {
  .c-card-step {
    display: flex;
    justify-content: space-between;
  }
}

.c-card-step__img {
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 7.5rem;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-card-step__img {
    width: 10rem;
    height: 8.75rem;
  }
}

.c-card-step__num {
  font-size: 1.875rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.625rem;
}

.c-card-step__num::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.9375rem;
  border: 0.9375rem solid transparent;
  border-top: 0.9375rem solid #000;
}

@media screen and (min-width: 768px) {
  .c-card-step:last-child .c-card-step__num::before {
    content: none;
  }
}

.c-card-step__img img {
  width: 3.125rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .c-card-step__img img {
    width: 3.75rem;
  }
}

.c-card-step__body {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .c-card-step__body {
    margin-top: 0.9375rem;
    width: calc(100% - 13.125rem);
  }
}

.c-card-step__title {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .c-card-step__title {
    justify-content: flex-start;
    font-size: 1.625rem;
  }
}

.c-card-step__title::before {
  content: "";
  width: 2.1875rem;
  height: 1.6875rem;
  background: url(../images/icon_check.png) no-repeat center/contain;
}

.c-card-step__text {
  font-size: 0.875rem;
  line-height: 2em;
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .c-card-step__text {
    font-size: 1rem;
  }
}

.c-section-title {
  font-size: 4.375rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  color: #fff5dd;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 300;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .c-section-title {
    font-size: 10.625rem;
  }
}

.c-section-title--white {
  color: #fff;
}

.c-section-title span {
  font-size: 1rem;
  color: #000;
  font-weight: bold;
  display: block;
  margin-top: 0.625rem;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.24em;
  width: 90%;
  display: flex;
  justify-content: center;
  font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .c-section-title span {
    font-size: 1.5rem;
  }
}

.c-section-title::after {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2px;
  background-color: #000;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .c-section-title::after {
    width: 3.75rem;
    height: 4px;
    margin-top: 3.125rem;
  }
}

.c-section-title--bar-white::after {
  background-color: #fff;
}

.p-about {
  background-color: #f7f6f5;
}

.p-about__inner {
  padding: 0 2.5rem;
}

.p-about__wrapper {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-about__wrapper {
    margin-top: 6.25rem;
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-about__img {
  width: 18.125rem;
  position: relative;
  margin: 0 auto;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .p-about__img {
    width: 55.5555555556%;
    top: 0;
    right: -5rem;
  }
}

.p-about__img img:nth-child(2) {
  width: 7rem;
  position: absolute;
  right: 0;
  left: 0;
  top: 8.125rem;
  margin: 0 auto;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .p-about__img img:nth-child(2) {
    width: 14.0625rem;
    top: 17.1875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-about__body {
    width: 44.4444444444%;
  }
}

.p-about__lead {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  font-size: 2.125rem;
  line-height: 1.6em;
  font-weight: bold;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .p-about__lead {
    font-size: 3.125rem;
    line-height: 1.7em;
    margin-top: 0;
    margin-bottom: 1.875rem;
  }
}

.p-about__text-box {
  font-size: 1rem;
  line-height: 2em;
}
@media screen and (min-width: 768px) {
  .p-about__text-box {
    line-height: 2.4em;
  }
}

.p-about__text:nth-child(n+2) {
  margin-top: 1.25rem;
}

.p-banner1 {
  background: url(../images/logo.png) repeat center/9.375rem 9.375rem;
  animation: bgiLoop 5s linear infinite;
}

.p-banner1__title {
  max-width: 55rem;
  width: 90%;
  margin: auto;
}

.p-banner__body {
  min-width: 17.5rem;
  max-width: 17.5rem;
  position: relative;
  margin: 1.25rem auto 6.875rem;
}
@media screen and (min-width: 768px) {
  .p-banner__body {
    max-width: 42.5rem;
    min-width: 42.5rem;
  }
}

.p-banner1__img-desktop {
  max-width: 14.375rem;
  width: 100%;
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .p-banner1__img-desktop {
    max-width: 35rem;
  }
}

.p-banner1__img-desktop img:nth-child(2) {
  width: 6.875rem;
  position: absolute;
  top: 2.1875rem;
  right: 0.875rem;
  background-color: #f7f6f5;
}
@media screen and (min-width: 768px) {
  .p-banner1__img-desktop img:nth-child(2) {
    width: 16.25rem;
    top: 5.625rem;
    right: 2.5rem;
  }
}

.p-banner1__img-sp {
  width: 4.375rem;
  position: absolute;
  top: 2.5625rem;
  left: 0;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .p-banner1__img-sp {
    width: 11.25rem;
    top: 5.125rem;
  }
}

.p-banner1__img-sp img:nth-child(2) {
  width: 3.375rem;
  position: absolute;
  top: 3.75rem;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #f7f6f5;
}
@media screen and (min-width: 768px) {
  .p-banner1__img-sp img:nth-child(2) {
    width: 8.375rem;
    top: 10rem;
  }
}

.p-banner1__btn {
  margin-top: 4.375rem;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-banner1__btn {
    margin-top: 0;
  }
}

.p-banner1__btn-img {
  width: 90%;
  position: absolute;
  bottom: 3.75rem;
  right: 0;
  left: 0;
  margin: auto;
  text-align: right;
  max-width: 68.75rem;
  z-index: 1;
}

.p-banner1__btn-img img {
  width: 9.375rem;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .p-banner1__btn-img img {
    width: 16.25rem;
  }
}

.p-banner__box {
  padding: 5.625rem 1.25rem 3.75rem 1.25rem;
  max-width: 63.75rem;
  width: 90%;
  margin: -12.5rem auto 0;
  background-color: #fff;
  border: 3px solid;
  border-radius: 0.1875rem;
}
@media screen and (min-width: 768px) {
  .p-banner__box {
    padding: 6.25rem 2.5rem 3.75rem;
    margin: -12.5rem auto 3.75rem;
  }
}

.p-banner__box-head {
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  width: 100%;
  font-weight: bold;
  padding: 1.875rem 0 3.75rem 0;
  line-height: 1;
  text-align: center;
  border-bottom: 3px solid;
}
@media screen and (min-width: 768px) {
  .p-banner__box-head {
    font-size: 2rem;
  }
}

.p-banner__box-head span {
  font-size: 3.125rem;
  padding-top: 0.625rem;
  display: inline-block;
  font-family: "Roboto", sans-serif;
}
@media screen and (min-width: 768px) {
  .p-banner__box-head span {
    font-size: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-banner__box-wrap {
    display: flex;
  }
}

.p-banner__price {
  width: 100%;
  padding: 3.75rem 0 3.125rem;
  margin-bottom: 1.25rem;
  border-radius: 0.625rem;
  background-color: #f7f6f5;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-banner__price {
    width: 23.75rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.p-banner__fukidasi {
  font-size: 0.75rem;
  width: 6.25rem;
  height: 6.25rem;
  color: #fff;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  top: -1.25rem;
  left: -1.25rem;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .p-banner__fukidasi {
    font-size: 1.125rem;
    width: 8.75rem;
    height: 8.75rem;
    line-height: 1.3;
  }
}

.p-banner__fukidasi::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: url(../images/plan_tag.png) no-repeat center/cover;
  animation: rotate-fukidashi 5s linear infinite both;
}

.p-banner__price-text {
  font-size: 5.625rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.25rem;
}
@media screen and (min-width: 768px) {
  .p-banner__price-text {
    font-size: 7.5rem;
  }
}

.p-banner__price-text .tag {
  font-size: 15%;
  display: inline-block;
  padding: 0.25rem 0.375rem;
  background-color: #222;
  color: #fff;
  border-radius: 2px;
  margin-right: 4px;
}

.p-banner__price-text .num {
  font-family: "Roboto", sans-serif;
  letter-spacing: -0.05em;
}

.p-banner__price-text small {
  font-size: 1.25rem;
}

.p-banner__price-sub-text {
  margin-top: 1.25rem;
  font-size: 1.5rem;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.p-banner__price-sub-text .tag {
  display: inline-block;
  background-color: #222;
  color: #fff;
  padding: 0.125rem 0.25rem;
  border-radius: 2px;
  margin-right: 4px;
  font-size: 60%;
}

.p-banner__price-sub-text .num {
  font-size: 120%;
  font-weight: bold;
  position: relative;
  bottom: -0.25rem;
  line-height: 1;
  font-family: "Roboto", sans-serif;
}

.p-banner__price-sub-text small {
  font-size: 50%;
  position: relative;
  bottom: -0.125rem;
  font-weight: bold;
}

.p-banner__list {
  margin-top: 1.25rem;
  font-size: 0.6875rem;
}
@media screen and (min-width: 768px) {
  .p-banner__list {
    flex: 1;
    padding: 0 1.875rem;
    font-size: 0.875rem;
    margin-top: 0;
  }
}

.p-banner__item {
  border-radius: 0.1875rem;
  padding: 0.5rem;
  font-weight: bold;
  background-color: #f7f6f5;
  display: flex;
  align-items: center;
}

.p-banner__item::before {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.375rem;
  background-color: #000;
}

.p-banner__item:nth-child(n+2) {
  margin-top: 0.4375rem;
}

@keyframes bgiLoop {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 9.375rem -9.375rem;
  }
}
@keyframes rotate-fukidashi {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.p-case {
  background-color: #000;
}

.p-case__title span {
  color: #fff;
}

.p-case__cards {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-case__cards {
    margin-top: 6.25rem;
  }
}

.p-case__card:nth-child(n+2) {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-case__card:nth-child(n+2) {
    margin-top: 7.5rem;
  }
}

.p-concept {
  background-color: #fff;
}

.p-concept__inner {
  padding: 0 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-concept__inner {
    padding: 0 1.25rem;
  }
}

.p-concept__wrapper {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-concept__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.p-concept__img {
  width: 90%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-concept__img {
    width: 46%;
    max-width: 31.25rem;
    margin: 0;
  }
}

.p-concept__body {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-concept__body {
    width: 52%;
    margin-top: 0;
  }
}

.p-concept__lead {
  font-size: 2.375rem;
  line-height: 1.6em;
  letter-spacing: 0.04em;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .p-concept__lead {
    font-size: 3.75rem;
  }
}

.p-concept__text-box {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-concept__text-box {
    margin-top: 1.875rem;
  }
}

.p-concept__text {
  line-height: 2em;
}
@media screen and (min-width: 768px) {
  .p-concept__text {
    line-height: 2.4em;
  }
}

.p-concept__text:nth-child(n+2) {
  margin-top: 1.25rem;
}

.p-concept__text img {
  width: 7.5rem;
  margin-left: 0.9375rem;
  display: inline-block;
  vertical-align: middle;
}

.p-copy {
  margin: 2.6875rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-family: "Roboto", sans-serif;
}

.p-feature {
  background-color: #000;
}

.p-feature__title span {
  color: #fff;
}

.p-feature__cards {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-feature__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.p-footer {
  background-color: #000;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-footer {
    height: 3.75rem;
  }
}

.p-footer__inner {
  max-width: 18.75rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-footer__inner {
    max-width: 90rem;
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-footer__list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.75rem;
    gap: 1.25rem;
  }
}

.p-footer__item a {
  display: block;
  padding: 0.625rem 0;
  font-size: 0.75rem;
  line-height: 1;
}

.p-fv {
  background-color: #f7f6f5;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .p-fv::before {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #000;
  }
}

.p-fv::after {
  content: "";
  display: block;
  width: 80%;
  height: 120%;
  position: absolute;
  top: 0;
  right: 0%;
  background: linear-gradient(to top right, rgba(255, 255, 255, 0) 50%, #000 50%) no-repeat top left/100% 100%;
}
@media screen and (min-width: 768px) {
  .p-fv::after {
    width: 45%;
    height: 200%;
    right: 30%;
  }
}

.p-fv__inner {
  padding-bottom: 6.25rem;
  max-width: 75rem;
  width: 90%;
  margin: auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-fv__inner {
    padding: 7.5rem 0 5.625rem 0;
  }
}

.p-fv__body {
  position: relative;
  z-index: 3;
}

.p-fv__catch {
  width: 100%;
  padding-top: 5.625rem;
}
@media screen and (min-width: 768px) {
  .p-fv__catch {
    max-width: 35.625rem;
    width: 50vw;
    margin-bottom: 2.5rem;
    padding-top: 3.125rem;
  }
}

.p-fv__catch img {
  max-height: 11.25rem;
}
@media screen and (min-width: 768px) {
  .p-fv__catch img {
    max-height: none;
  }
}

.p-fv__text {
  font-size: 0.75rem;
  line-height: 1.8;
  font-weight: bold;
  margin: 1.25rem 0 18.75rem 0.3125rem;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .p-fv__text {
    font-size: 1.25rem;
    line-height: 2;
    margin-bottom: 0.625rem;
  }
}

.p-fv__text span {
  background-color: #f7f6f5;
}

.p-fv__sub-text {
  font-size: 0.75rem;
  line-height: 2.4;
  text-align: center;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .p-fv__sub-text {
    font-size: 0.875rem;
    text-align: left;
  }
}

.p-fv__sub-text span {
  background-color: #000;
  color: #fff;
}

.p-fv__pc {
  width: 21.25rem;
  max-width: 19.375rem;
  position: absolute;
  top: 22.5rem;
  left: 30%;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .p-fv__pc {
    max-width: 44.375rem;
    width: 100%;
    left: 66%;
    top: 7.5rem;
  }
}

.p-fv__pc img:nth-child(2) {
  width: 8.5rem;
  position: absolute;
  background-color: #f7f6f5;
  top: 3.375rem;
  left: 7.75rem;
}
@media screen and (min-width: 768px) {
  .p-fv__pc img:nth-child(2) {
    left: 17.5rem;
    top: 7.5rem;
    width: 18.75rem;
  }
}

.p-fv__sp {
  width: 15.625rem;
  position: absolute;
  z-index: 2;
  top: 15rem;
  right: 45%;
  left: auto;
  margin: auto;
  transform: scale(0.4);
}
@media screen and (min-width: 768px) {
  .p-fv__sp {
    left: 56%;
    width: 16.25rem;
    margin-top: 9.375rem;
    right: auto;
    top: 7.5rem;
    transform: scale(1);
  }
}

.p-fv__sp img:nth-child(1) {
  padding-bottom: 5.625rem;
}

.p-fv__sp img:nth-child(2) {
  width: 120%;
  max-width: 120%;
  position: absolute;
  z-index: -1;
  top: 6.875rem;
  right: -3.75rem;
  opacity: 0.6;
}

.p-fv__sp img:nth-child(3) {
  width: 11.625rem;
  position: absolute;
  top: 13.875rem;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #f7f6f5;
}
@media screen and (min-width: 768px) {
  .p-fv__sp img:nth-child(3) {
    width: 12rem;
    top: 14.4375rem;
  }
}

.p-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  background-color: #000;
}

.p-header__logo {
  width: 5rem;
  height: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .p-header__logo {
    width: 7.125rem;
    height: 1.5rem;
  }
}

.p-header__logo img {
  width: 98%;
}

.p-header__menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
  color: #fff;
  flex-grow: 1;
}

.p-header__signin {
  font-size: 0.75rem;
}

.p-header__begin {
  width: 6.25rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;
  font-size: 0.75rem;
}

.p-header__humbergar {
  width: 2.1875rem;
  height: 1.5625rem;
  position: relative;
  cursor: pointer;
  margin-left: 1.25rem;
}

.p-header__humbergar span {
  width: 100%;
  height: 3px;
  background-color: #fff;
  position: absolute;
  left: 0;
  transition: 0.3s;
}

.p-header__humbergar span:nth-child(1) {
  top: 0;
}

.p-header__humbergar span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.p-header__humbergar span:nth-child(3) {
  bottom: 0;
}

.p-header__humbergar.show span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.p-header__humbergar.show span:nth-child(2) {
  opacity: 0;
}

.p-header__humbergar.show span:nth-child(3) {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%) rotate(-45deg);
}

.p-header__toggle {
  width: 10.6875rem;
  display: none;
  position: fixed;
  top: 3.75rem;
  right: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-header__toggle {
    top: 6.25rem;
  }
}

.p-header__item {
  background-color: #f7f6f5;
  margin: 0 0 2px;
}

.p-header__item a {
  display: block;
  padding: 0.625rem;
  position: relative;
  z-index: 1;
  font-size: 0.875rem;
}

.p-merit {
  background-color: #f7f6f5;
  overflow-x: hidden;
}

.p-merit__cards {
  margin-top: 2.5rem;
}

@media screen and (min-width: 768px) {
  .p-merit__card:nth-child(n+2) {
    margin-top: 11.25rem;
  }
}

.p-merit__card:nth-child(3) {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-merit__card:nth-child(3) {
    margin-top: 22.5rem;
  }
}

.p-news__inner {
  max-width: 59.375rem;
}

.p-news__title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.p-news__list {
  margin-top: 3.125rem;
}

.p-news__item {
  display: block;
  border-bottom: 2px solid #f7f6f5;
  padding: 0.625rem 0;
  font-size: 0.875rem;
  line-height: 2em;
}

.p-news__item:nth-child(1) {
  border-top: 2px solid #f7f6f5;
}

.p-news__item a {
  display: block;
}
@media screen and (min-width: 768px) {
  .p-news__item a {
    display: flex;
  }
}

.p-news__time {
  display: block;
  font-weight: bold;
  padding-right: 2.5rem;
  font-family: "Roboto", sans-serif;
}

.p-news__text {
  font-weight: normal;
}

.p-news__btn {
  margin-top: 0.625rem;
  line-height: 1.4em;
  text-align: right;
}

.p-news__btn a {
  border-bottom: 1px solid;
}

@media screen and (min-width: 768px) {
  .p-step__inner {
    max-width: 56.25rem;
  }
}

.p-step__cards {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-step__cards {
    margin-top: 6.25rem;
  }
}

.p-step__card:nth-child(n+2) {
  margin-top: 3.125rem;
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}