@use "global" as *;

.c-btn {
  width: 80%;
  font-size: rem(15);
  padding: rem(30);
  display: inline-block;
  font-weight: bold;
  line-height: 1;
  border-radius: rem(100);
  max-width: rem(760);
  text-align: center;
  position: relative;
  background-color: $black;
  color: $white;
  z-index: 2;

  @include mq(md) {
    font-size: rem(36);
    padding: rem(40) rem(60);
  }
}