@use "global" as *;

.c-card-step {
  @include mq(md) {
    display: flex;
    justify-content: space-between;
  }
}

.c-card-step__img {
  background-color: $black;
  color: $white;
  width: 100%;
  height: rem(120);
  text-align: center;
  position: relative;

  @include mq(md) {
    width: rem(160);
    height: rem(140);
  }
}

.c-card-step__num {
  font-size: rem(30);
  padding-top: rem(5);
  padding-bottom: rem(10);
}

.c-card-step__num::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: rem(-15);
  border: rem(15) solid transparent;
  border-top: rem(15) solid $black;
}

.c-card-step:last-child .c-card-step__num::before {
  @include mq(md) {
    content: none;
  }
}

.c-card-step__img img {
  width: rem(50);
  margin-left: auto;
  margin-right: auto;

  @include mq(md) {
    width: rem(60);
  }
}

.c-card-step__body {
  margin-top: rem(50);

  @include mq(md) {
    margin-top: rem(15);
    width: calc(100% - #{rem(210)});
  }
}

.c-card-step__title {
  font-size: rem(20);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  @include mq(md) {
    justify-content: flex-start;
    font-size: rem(26);
  }
}

.c-card-step__title::before {
  content: "";
  width: rem(35);
  height: rem(27);
  background: url(../images/icon_check.png) no-repeat center / contain;
}

.c-card-step__text {
  font-size: rem(14);
  line-height: 2em;
  margin-top: rem(20);

  @include mq(md) {
    font-size: rem(16);
  }
}