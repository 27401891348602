@use "global" as *;

.c-card-feature {
  width: 100%;
  position: relative;
  padding: rem(38) rem(28);
  margin-bottom: rem(50);
  background-color: $white;
  box-shadow: rem(7) rem(7) 0 rgba($color: $white, $alpha: 0.2);

  @include mq(md) {
    padding: rem(50);
    margin-bottom: rem(80);
  }
}

.c-card-feature:last-child {
  margin-bottom: 0;
}

.c-card-feature:nth-child(n+4) {
  @include mq(md) {
    margin-bottom: 0;
  }
}

.c-card-feature:nth-child(1) {
  padding-top: rem(350);

  @include mq(md) {
    padding-top: rem(50);
  }
}

.c-card-feature:nth-child(2),
.c-card-feature:nth-child(3) {
  @include mq(md) {
    width: 48%;
  }
}

.c-card-feature:nth-child(4),
.c-card-feature:nth-child(5),
.c-card-feature:nth-child(6) {
  @include mq(md) {
    width: 31%;
    padding: rem(30);
  }
}

.c-card-feature__num {
  font-family: $font-mon;
  font-weight: bold;
  font-size: rem(60);
  line-height: 1;
}

.c-card-feature__icon {
  position: absolute;
  top: rem(-30);
  right: rem(18);
  width: rem(120);
  z-index: 3;
}

.c-card-feature:nth-child(1) .c-card-feature__body {
  @include mq(md) {
    width: 45%;
  }
}

.c-card-feature__title {
  font-weight: bold;
  line-height: 1.6em;
  font-size: rem(20);
  letter-spacing: 0.08rem;
  margin-top: rem(18);
  margin-bottom: rem(10);
}

.c-card-feature__text {
  font-size: rem(15);
  letter-spacing: 0.02em;
  line-height: 1.8;

  @include mq(md) {
    font-size: rem(16);
    line-height: 1.6;
    opacity: 0.8;
  }
}

.c-card-feature__img {
  width: 100%;
  height: rem(300);
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/feature__image1.png) repeat center / rem(672) rem(564);
  animation: bgiLoop_thumb_min 50s linear infinite;

  @include mq(md) {
    width: 50%;
    background: url(../images/feature__image1.png) repeat center / rem(1120) rem(940);
    height: 100%;
  }
}

@keyframes bgiLoop_thumb_min {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: rem(672) rem(-564);
  }
}