@use "global" as *;

.l-banner1 {
  padding: rem(150) 0;
  margin-top: rem(100);

  @include mq(md) {
    padding: rem(280) 0 rem(180);
    margin-top: rem(170);
  }
}