@use "global" as *;

.c-card-case__title {
  display: flex;
  align-items: center;
  line-height: 1.6em;
  margin-bottom: rem(16);

  @include mq(md) {
    margin-bottom: rem(30);
    align-items: flex-start;
  }
}

.c-card-case__title::before {
  content: "";
  width: rem(50);
  height: rem(50);
  margin-right: rem(20);
  background: url(../images/case3.png) no-repeat center / 60%;
  background-color: #f7f6f5;
  border-radius: 50%;

  @include mq(md) {
    width: rem(120);
    height: rem(120);
    margin-right: rem(40);
    background: url(../images/case3.png) no-repeat center / rem(70);
    background-color: #f7f6f5;
  }
}

.c-card-case__title span {
  font-size: rem(16);
  line-height: 2em;
  padding: rem(10);
  position: relative;
  background-color: #f7f6f5;
  border-radius: rem(80);
  font-weight: bold;
  letter-spacing: 0.08em;
  flex: 1;
  text-align: center;
  box-shadow: rem(2) rem(2) rem(20) rgba($color: $black, $alpha: 0.2);

  @include mq(md) {
    font-size: rem(34);
    line-height: 1.5em;
    padding: rem(40) rem(50);
    margin-right: rem(170);
  }
}

.c-card-case__title span::before {
  content: "";
  position: absolute;
  left: 0;
  border: rem(15) solid transparent;
  border-right: rem(30) solid #f7f6f5;
  top: 28%;
  margin-left: rem(-25);

  @include mq(md) {
    top: 40%;
    margin-left: rem(-40);
  }
}

.c-card-case__body {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @include mq(md) {
    flex-direction: row;
  }
}

.c-card-case__text {
  padding: rem(15) rem(20);
  font-size: rem(13);
  line-height: 2em;
  background: #f7f6f5;
  border-radius: rem(10);
  flex: 1;
  position: relative;
  box-shadow: rem(2) rem(2) rem(20) rgba($color: rgba($color: $black, $alpha: 0.2), $alpha: 0.2);
  position: relative;

  @include mq(md) {
    line-height: 1.9em;
    font-size: rem(17);
    padding: rem(40) rem(50);
    margin-left: rem(170);
  }
}

.c-card-case__text::before {
  content: "";
  position: absolute;
  top: 40%;
  left: rem(50);
  border: rem(15) solid transparent;
  border-left: rem(30) solid #f7f6f5;
  margin-left: rem(-32);
  transform: rotate(180deg);
  transform-origin: left;

  @include mq(md) {
    left: auto;
    right: rem(-40);
    margin-left: rem(-40);
    transform: rotate(0);
  }
}

.c-card-case__img {
  margin-right: rem(20);

  @include mq(md) {
    margin-right: 0;
    margin-left: rem(40);
  }
}

.c-card-case__img img {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;
  position: relative;

  @include mq(md) {
    width: rem(120);
    height: rem(120);
  }
}