@use "global" as *;

.p-merit {
  background-color: #f7f6f5;
  overflow-x: hidden;
}

.p-merit__cards {
  margin-top: rem(40);
}

.p-merit__card:nth-child(n+2) {
  @include mq(md) {
    margin-top: rem(180);
  }
}

.p-merit__card:nth-child(3) {
  margin-top: rem(20);

  @include mq(md) {
    margin-top: rem(360);
  }
}