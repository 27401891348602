@use "global" as *;

.c-section-title {
  font-size: rem(70);
  font-family: $font-robo;
  text-transform: uppercase;
  color: #fff5dd;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 300;
  text-align: center;

  @include mq(md) {
    font-size: rem(170);
  }
}

.c-section-title--white {
  color: $white;
}

.c-section-title span {
  font-size: rem(16);
  color: $black;
  font-weight: bold;
  display: block;
  margin-top: rem(10);
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.24em;
  width: 90%;
  display: flex;
  justify-content: center;
  font-family: $font-main;
  line-height: 1.5;

  @include mq(md) {
    font-size: rem(24);
  }
}

.c-section-title::after {
  content: "";
  display: block;
  width: rem(40);
  height: 2px;
  background-color: $black;
  margin-top: rem(32);
  margin-left: auto;
  margin-right: auto;

  @include mq(md) {
    width: rem(60);
    height: 4px;
    margin-top: rem(50);
  }
}

.c-section-title--bar-white::after {
  background-color: $white;
}