@use "global" as *;

.p-step__inner {
  @include mq(md) {
    max-width: rem(900);
  }
}

.p-step__cards {
  margin-top: rem(40);

  @include mq(md) {
    margin-top: rem(100);
  }
}

.p-step__card:nth-child(n+2) {
  margin-top: rem(50);
}