@use "global" as *;

.p-news__inner {
  max-width: rem(950);
}

.p-news__title {
  font-size: rem(24);
  font-weight: bold;
  text-align: center;
}

.p-news__list {
  margin-top: rem(50);
}

.p-news__item {
  display: block;
  border-bottom: 2px solid #f7f6f5;
  padding: rem(10) 0;
  font-size: rem(14);
  line-height: 2em;
}

.p-news__item:nth-child(1) {
  border-top: 2px solid #f7f6f5;
}

.p-news__item a {
  display: block;

  @include mq(md) {
    display: flex;
  }
}

.p-news__time {
  display: block;
  font-weight: bold;
  padding-right: rem(40);
  font-family: $font-robo;
}

.p-news__text {
  font-weight: normal;
}

.p-news__btn {
  margin-top: rem(10);
  line-height: 1.4em;
  text-align: right;
}

.p-news__btn a {
  border-bottom: 1px solid;
}