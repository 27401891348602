@use "global" as *;

.p-feature {
  background-color: $black;
}

.p-feature__title span {
  color: $white;
}

.p-feature__cards {
  margin-top: rem(40);

  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}