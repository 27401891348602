@use "global" as *;

.p-fv {
  background-color: #f7f6f5;
  position: relative;
  overflow: hidden;
}

.p-fv::before {
  @include mq(md) {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $black;
  }
}

.p-fv::after {
  content: "";
  display: block;
  width: 80%;
  height: 120%;
  position: absolute;
  top: 0;
  right: 0%;
  background: linear-gradient(to top right, rgba($white, 0) 50%, $black 50%) no-repeat top left / 100% 100%;

  @include mq(md) {
    width: 45%;
    height: 200%;
    right: 30%;
  }
}

.p-fv__inner {
  padding-bottom: rem(100);
  max-width: rem(1200);
  width: 90%;
  margin: auto;
  position: relative;

  @include mq(md) {
    padding: rem(120) 0 rem(90) 0;
  }
}

.p-fv__body {
  position: relative;
  z-index: 3;
}

.p-fv__catch {
  width: 100%;
  padding-top: rem(90);

  @include mq(md) {
    max-width: rem(570);
    width: 50vw;
    margin-bottom: rem(40);
    padding-top: rem(50);
  }
}

.p-fv__catch img {
  max-height: rem(180);

  @include mq(md) {
    max-height: none;
  }
}

.p-fv__text {
  font-size: rem(12);
  line-height: 1.8;
  font-weight: bold;
  margin: rem(20) 0 rem(300) rem(5);
  display: inline-block;

  @include mq(md) {
    font-size: rem(20);
    line-height: 2;
    margin-bottom: rem(10);
  }
}

.p-fv__text span {
  background-color: #f7f6f5;
}

.p-fv__sub-text {
  font-size: rem(12);
  line-height: 2.4;
  text-align: center;
  font-weight: bold;

  @include mq(md) {
    font-size: rem(14);
    text-align: left;
  }
}

.p-fv__sub-text span {
  background-color: $black;
  color: $white;
}

.p-fv__pc {
  width: rem(340);
  max-width: rem(310);
  position: absolute;
  top: rem(360);
  left: 30%;
  z-index: 1;

  @include mq(md) {
    max-width: rem(710);
    width: 100%;
    left: 66%;
    top: rem(120);
  }
}

.p-fv__pc img:nth-child(2) {
  width: rem(136);
  position: absolute;
  background-color: #f7f6f5;
  top: rem(54);
  left: rem(124);

  @include mq(md) {
    left: rem(280);
    top: rem(120);
    width: rem(300);
  }
}

.p-fv__sp {
  width: rem(250);
  position: absolute;
  z-index: 2;
  top: rem(240);
  right: 45%;
  left: auto;
  margin: auto;
  transform: scale(0.4);

  @include mq(md) {
    left: 56%;
    width: rem(260);
    margin-top: rem(150);
    right: auto;
    top: rem(120);
    transform: scale(1);
  }
}

.p-fv__sp img:nth-child(1) {
  padding-bottom: rem(90);
}

.p-fv__sp img:nth-child(2) {
  width: 120%;
  max-width: 120%;
  position: absolute;
  z-index: -1;
  top: rem(110);
  right: rem(-60);
  opacity: 0.6;
}

.p-fv__sp img:nth-child(3) {
  width: rem(186);
  position: absolute;
  top: rem(222);
  right: 0;
  left: 0;
  margin: auto;
  background-color: #f7f6f5;

  @include mq(md) {
    width: rem(192);
    top: rem(231);
  }
}