@use "global" as *;

.p-case {
  background-color: $black;
}

.p-case__title span {
  color: $white;
}

.p-case__cards {
  margin-top: rem(40);

  @include mq(md) {
    margin-top: rem(100);
  }
}

.p-case__card:nth-child(n+2) {
  margin-top: rem(40);

  @include mq(md) {
    margin-top: rem(120);
  }
}