@use "global" as *;

.p-about {
  background-color: #f7f6f5;
}

.p-about__inner {
  padding: 0 rem(40);
}

.p-about__wrapper {
  margin-top: rem(40);

  @include mq(md) {
    margin-top: rem(100);
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-about__img {
  width: rem(290);
  position: relative;
  margin: 0 auto;
  z-index: 10;

  @include mq(md) {
    width: calc(600/1080*100%);
    top: 0;
    right: rem(-80);
  }
}

.p-about__img img:nth-child(2) {
  width: rem(112);
  position: absolute;
  right: 0;
  left: 0;
  top: rem(130);
  margin: 0 auto;
  z-index: -1;

  @include mq(md) {
    width: rem(225);
    top: rem(275);
  }
}

.p-about__body {
  @include mq(md) {
    width: calc(480/1080*100%);
  }
}

.p-about__lead {
  margin-bottom: rem(20);
  margin-top: rem(20);
  font-size: rem(34);
  line-height: 1.6em;
  font-weight: bold;
  white-space: nowrap;

  @include mq(md) {
    font-size: rem(50);
    line-height: 1.7em;
    margin-top: 0;
    margin-bottom: rem(30);
  }
}

.p-about__text-box {
  font-size: rem(16);
  line-height: 2em;

  @include mq(md) {
    line-height: 2.4em;
  }
}

.p-about__text:nth-child(n+2) {
  margin-top: rem(20);
}