@use "global" as *;

.c-card-merit {
  position: relative;

  @include mq(md) {
    max-width: rem(960);
    width: 90%;
    margin: auto;
  }
}

.c-card-merit__img {
  width: 120%;
  margin-bottom: rem(-70);
  position: relative;
  top: 0;
  right: -5%;
  z-index: 1;

  @include mq(md) {
    width: 130%;
    position: absolute;
    top: rem(-90);
    right: -45%;
  }
}

.c-card-merit:nth-child(2) .c-card-merit__img {
  width: rem(340);
  left: auto;
  right: rem(-20);
  margin-bottom: rem(-60);

  @include mq(md) {
    width: rem(680);
    height: auto;
    position: absolute;
    top: rem(-110);
    left: rem(-60);

  }
}

.c-card-merit:nth-child(3) .c-card-merit__img {
  max-width: rem(380);
  width: 100%;
  right: 0;
  top: rem(10);
  margin-bottom: rem(-40);

  @include mq(md) {
    right: rem(-180);
    top: rem(-110);
    max-width: rem(740);
    width: 70%;
    z-index: 3;
  }
}

.c-card-merit:nth-child(3) .c-card-merit__img img:nth-child(1) {
  position: relative;
}

.c-card-merit:nth-child(3) .c-card-merit__img img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.c-card-merit__body {
  padding: rem(30);
  position: relative;
  background-color: $white;

  @include mq(md) {
    z-index: 2;
    padding: rem(60) rem(50);
    max-width: rem(540);
    width: 50%;
  }
}

.c-card-merit:nth-child(2) .c-card-merit__body {
  margin-top: rem(30);

  @include mq(md) {
    margin-right: 0;
    margin-left: auto;
  }
}

.c-card-merit__num {
  font-size: rem(70);
  line-height: 1;
  letter-spacing: -2px;
  font-weight: bold;
  font-family: $font-mon;
  display: flex;
  align-items: center;
  gap: rem(10);

  @include mq(md) {
    font-size: rem(40);
  }
}

.c-card-merit__num::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 20px;
  border-color: transparent transparent transparent $black;
  display: block;
}

.c-card-merit__title {
  font-size: rem(24);
  line-height: 1.6;
  padding-bottom: rem(10);
  padding-top: rem(18);
  letter-spacing: 0.04em;

  @include mq(md) {
    font-size: rem(26);
    line-height: 1.7em;
    padding: rem(20) 0;
  }
}

.c-card-merit__text {
  font-size: rem(16);
  line-height: 2em;
  letter-spacing: 0.01em;

}

.c-card-merit__bottom-img {
  width: rem(260);
  position: absolute;
  bottom: rem(-130);
  left: rem(-100);

  @include mq(md) {
    width: rem(540);
    left: rem(-260);
    bottom: rem(-290);
    z-index: 2;
  }
}