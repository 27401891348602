@use "global" as *;

.p-concept {
  background-color: $white;
}

.p-concept__inner {
  padding: 0 rem(40);

  @include mq(md) {
    padding: 0 rem(20);
  }
}

.p-concept__wrapper {
  margin-top: rem(40);

  @include mq(md) {
    display: flex;
    justify-content: space-between;
  }
}

.p-concept__img {
  width: 90%;
  margin: 0 auto;

  @include mq(md) {
    width: 46%;
    max-width: rem(500);
    margin: 0;
  }
}

.p-concept__body {
  margin-top: rem(30);

  @include mq(md) {
    width: 52%;
    margin-top: 0;
  }
}

.p-concept__lead {
  font-size: rem(38);
  line-height: 1.6em;
  letter-spacing: 0.04em;
  font-weight: bold;

  @include mq(md) {
    font-size: rem(60);
  }
}

.p-concept__text-box {
  margin-top: rem(20);

  @include mq(md) {
    margin-top: rem(30);
  }
}

.p-concept__text {
  line-height: 2em;

  @include mq(md) {
    line-height: 2.4em;
  }
}

.p-concept__text:nth-child(n+2) {
  margin-top: rem(20);
}

.p-concept__text img {
  width: rem(120);
  margin-left: rem(15);
  display: inline-block;
  vertical-align: middle;
}