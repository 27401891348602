@use "global" as *;

.p-footer {
  background-color: $black;
  color: $white;
  text-align: center;

  @include mq(md) {
    height: rem(60);
  }
}

.p-footer__inner {
  max-width: rem(300);
  padding-top: rem(30);
  padding-bottom: rem(30);

  @include mq(md) {
    max-width: rem(1440);
    padding: 0;
  }
}

.p-footer__list {
  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(60);
    gap: rem(20);
  }
}

.p-footer__item a {
  display: block;
  padding: rem(10) 0;
  font-size: rem(12);
  line-height: 1;
}