@use "global" as *;

.p-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  background-color: $black;
}

.p-header__logo {
  width: rem(80);
  height: rem(20);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include mq(md) {
    width: rem(114);
    height: rem(24);
  }
}

.p-header__logo img {
  width: 98%;
}

.p-header__menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: rem(10);
  color: $white;
  flex-grow: 1;
}

.p-header__signin {
  font-size: rem(12);
}

.p-header__begin {
  width: rem(100);
  height: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  color: $black;
  font-size: rem(12);
}

.p-header__humbergar {
  width: rem(35);
  height: rem(25);
  position: relative;
  cursor: pointer;
  margin-left: rem(20);
}

.p-header__humbergar span {
  width: 100%;
  height: 3px;
  background-color: $white;
  position: absolute;
  left: 0;
  transition: .3s;
}

.p-header__humbergar span:nth-child(1) {
  top: 0;
}

.p-header__humbergar span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.p-header__humbergar span:nth-child(3) {
  bottom: 0;
}

.p-header__humbergar.show span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.p-header__humbergar.show span:nth-child(2) {
  opacity: 0;
}

.p-header__humbergar.show span:nth-child(3) {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%) rotate(-45deg);
}

.p-header__toggle {
  width: rem(171);
  display: none;
  position: fixed;
  top: rem(60);
  right: rem(20);

  @include mq(md) {
    top: rem(100);
  }
}

.p-header__item {
  background-color: #f7f6f5;
  margin: 0 0 2px;
}

.p-header__item a {
  display: block;
  padding: rem(10);
  position: relative;
  z-index: 1;
  font-size: rem(14);
}